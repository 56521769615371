.school_row{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
}

.logo{
  margin: auto 0;
  margin-right: 20px;
  width: 150px;
  height: 100%;
}
.school_name{
  font-size: 30px;
  font-weight: bold;
  margin: 0;
}

.school_information{
  width: 100%;
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  margin-top: 10px;
  margin-bottom:10px;
}

.school_description{
  width: 100%;
  text-align: left;
  margin-left: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.school_description #info{
  font-size: 15px;
  margin: 2px 0;
}

.school_accomplishments{
  margin:0;
  display:flex;
  flex-direction:row;
  margin-top: 50px;
  margin-bottom: 50px;
  flex-wrap:wrap;
  justify-content: space-between;
}

.pd{
  min-width: 358px;
  width: 48%;
}
.tufts{
  min-width: 358px;
  width: 48%;
}

.course_work{
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 358px;
  width: 48%
}

.coursework_table{
  width: 100%;
}

#header{
  text-align:center;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 1px solid var(--header-one);
  margin-top: 0;
}

#award_year{
  padding-right: 10px;
}

#award_name{
  font-weight: bold;
  text-align: left;
  margin-bottom: 0px;
  margin-top: 5px;
}

#description{
  font-weight: normal;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 5px;
}

#ec_name{
  font-weight: bold;
  padding-right: 10px;
}

#course_name{
  font-weight:normal;
  text-align:left;
  float:left;
}

#course_score{
  float:right;
}
.italics{
  font-style: italic;
}


/* Media Queries: Tabled Portrait */
@media screen and (max-width: 1022px) {
    .logo{display:none;}
    .logo_container{display:none;}
    .school_name{text-align: center; font-size: 25px; text-decoration: underline; margin-left: 0px}
    #info{text-align: center;}
    .school_description{width: 100%;}
    .school_accomplishments{margin-bottom: 50px; justify-content: center;}
    .education_header{font-size: 30px}
    
}
