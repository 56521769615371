.experience_entry{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border: 3px solid transparent;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 20px;
  transition-duration: 0.5s;
  transition-timing-function: linear;
  text-decoration: none;
  color: inherit;
}
.experience_entry:hover{
  border: 3px solid var(--font-color);
}

.experience_left_text{
  margin: 0;
  flex-basis: 80%;
  color: gray;
}

.experience_entry_image{
  width: 150px;
  height: 100%;
  margin-right: 20px
}

#button_shift{
  margin-top: 30px;
}

.experience_entry_header{
  font-size: 25px;
  margin: 0px;
}
#date{
  font-weight:lighter;
  font-size: 20px;
  font-style:italic;
}
li{
  font-size:15px;
  color:gray;
}
#button_shift{
  margin:0 auto;
  width:64px;
}

#website_label{
  text-align:center;
  margin: 0 auto;
  color:var(--font-color);
}


.language_image{
  width: 70px;
  margin: 0 5px;
}

.all_languages{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.language_label{
  margin-top: 3px;
  text-align: center;
  font-size: 20px;
}

.language_image_and_text{
  -webkit-transition: .5s ease;
  margin: 0 10px;

}

.language_image_and_text:hover{
  transform: scale(1.25);
}
.language_header{
  margin: 10px auto;
  text-align:center;
  font-size: 20px;
}
.dash{
  margin: 0 auto;
  text-align: center;
  color: var(--header-one);
  font-size: 20px;
  padding: 0px;
}