h1{
  font-size: 45px;
  font-weight:900;
  text-align: center;
  margin-top: 0px;
  color:var(--font-color)
}
h2{
  font-size: 30px;
  margin: 10px 0;
  color:var(--font-color)
}

h3{
  color:var(--font-color)
}

#article-title{
  border-top: 1px solid var(--header-one);
  border-bottom: 1px solid var(--header-one);
}

.article-content{
  margin-top: 20px;
  font-size: 18px;
}

.row_flex{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.column-flex{
  display: flex;
  flex-direction: column;
}
.half{
  width: 50%;
}

.image_container{
  width: 50%;
  min-width: 300px;
  height: 100%;
  margin: auto 0;
}

.image_label{
  text-align:center;
  width: 100%;
  color: gray;
  margin: 0;
  font-size: 15px;
}

.article_table{
  border-collapse: collapse;
  border: 1px solid black;
}

.article_table td{
  border-collapse: collapse;
  border: 1px solid black;
  text-align: center;
}
.article_table th{
  border-collapse: collapse;
  border: 1px solid black;
  text-align: center;
}