.blog-article-preview-container{
  width: 100%;
  margin: 30px 0;
  border: 1px solid var(--header-one);
  border-radius: 10px;
  color: var(--text-color);
}

.blog-article-image{
  width:100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid var(--header-one);
  
}

.blog-article-preview-title{
  margin: 10px 4%;
  font-size: 30px;
  font-weight: bold;
  margin-bottom:0;
}

.blog-article-preview-description{
  font-size: 15px;
  margin: 10px 4%;
}

.blog-article-preview-info{
  border-bottom:1px solid var(--header-one);
}

.blog-article-preview-date{
  color: var(--font-color);
  font-style: italic;
  font-size: 13px;
  margin: 3px;
  margin-left: 4%;
  font-weight: bold;
}