.bottom{
  border-top: 1px solid var(--header-one);
  margin-top: 80px;
  margin-bottom: 20px;
  width: 100%;
  margin-top:50px;
}

.topcoder{
  width:78px;
}

.button_label{
  text-align: center;
  margin-bottom: 0px;
}

#topcoder_button_one.contact_button{
  margin: 0px;
  margin-left: 15px;
  margin-top: 15px;
  height:27px;
  width:48px;
  display: inline-block;
}

.contact{
  text-align: center;
  font-size: 20px;
}


.button_links{
  margin-top: 30px;
  display:flex;
  flex-direction:row;
  justify-content: center;
  vertical-align: center;
}

.contact_button{
  align-content: center;
  margin: 0 30px;;
}