:root{
  --header-one: #EA5455;
  --text-color: black;
  --font-color: #33475b;
}

.underline{
  border-bottom: 1px solid var(--header-one);
  padding-top: 20px;
}

.everything{
  padding-top: 120px;
  width: 70%;
  margin: 0 auto;
  
}

body{
  font-size: 15px;
}