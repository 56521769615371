
.project_entry{
  padding: 25px 0;
  display: flex;
  display: row;
}

.project_text{
  margin-left: 2%;
}
.project_name{
  font-size: 25px;
  margin: 0px;
  font-weight: bold;
}
.project_group{
  font-size: 20px;
  margin: 0;
}

.date{
  font-weight: lighter;
  font-size: 20px;
  font-style:italic;
  margin: 0;
}
.project_image{
  width: 100%;
}

.project_image_container{
  min-width:15%;
  max-width: 15%;
}
.project_languages{
  margin:0;
  margin-right: 5px;
}
.project_description{
  margin:0;
  color: gray;
}

.bullet_list{
  display:flex;
  flex-direction:row;
}

.bullets{
  display:flex;
}
.bullet{
  margin: 0;
  margin-right: 5px;
}
