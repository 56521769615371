#quote{
    font-size: 20px;
    font-style: italic;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    color:white;
    width: 86%;
    padding: 0px;
    padding-left: 2%;
    padding-right:2%;
}

.quote-container{
    height: 100%;
    display: table;
    padding: 40px 10px;
    width: 100%;
}

#quotationMark{
    margin: 0;
    font-size: 50px;
    color: red;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    height: 1px;
    display: table-cell;
    padding-left: 1%;
    width: 3%;
    padding-right: 1%;
}

#quotationMarkLeft{
    margin: 0;
    font-size: 50px;
    color: red;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    height: 1px;
    margin-top: auto;
    display: table-cell;
    padding-right: 5px;
    padding-left: 1%;
    width: 3%;
    padding-right: 1%;
}

#google-trends-header{
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    color: var(--font-color);
    margin-bottom: 0;
}

.google-trends-bottom{
    width: 50%;
    margin-left: 25%;
    
}

.mapcontainer{
    display: flex;
    direction: row;
    justify-content: space-between;
}

.mapcontainer .map{
    width: 48%;
}