body{
    font-family: 'avenir';
}
.top{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#main_photo{
  width:300px;
  height: 100%;
  margin: 0 auto;
}
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.information_div{
  margin-right: 3%;
  width:100%;
  text-align: left;
  flex-basis: calc(97% - 300px);
  flex-shrink: 0;
  flex-grow: 1;
}
.big_name{
  margin: 0;
  text-align: left;
  font-size: 45px;
  font-weight:900;
  color: var(--font-color);
}
.medium_text{
  font-size: 20px;
  font-style: oblique;
  margin: 0;
}

.contact_button{
  align-content: center;
  margin: 0 30px;;
}

.phone_number{
  font-size: 20px;
  margin: 5px 0;

}

.bio_resume{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.resume_label{
  margin: auto 0;
  margin-left: 10px;
  font-weight:bold;
}

.resume_button{
  color: black;
}

.resume_button:hover{
  color: #EA5455
}

#transition{
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 3s; /* Firefox < 16 */
      -ms-animation: fadein 3s; /* Internet Explorer */
       -o-animation: fadein 3s; /* Opera < 12.1 */
          animation: fadein 3s;
}

/* Media Queries: Tabled Portrait */
@media screen and (max-width: 768px) {
    .header_text{margin: 0 4px;font-size: 9px}
    .header{margin-bottom: 10px;}
    .contact_button{margin: 0 25px;}
    .topcoder{visibility: hidden; display: none;}
    .header_text{margin-left: 5px; margin-right: 5px}
}

.button_label{
  text-align: center;
  margin-bottom: 0px;
}

.button_links{
  margin-top: 30px;
  display:flex;
  flex-direction:row;
  justify-content: flex-start;
  vertical-align: center;
}

.contact_button{
  align-content: center;
  margin: 0 30px;
  margin-left: 0px;
}