.menu{
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.header{
  z-index: 10;
  position: fixed;
  width: 100%;
  padding: 0px;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom:80px;
  box-shadow: 0px 0px 0px 3px rgba(0,0,0,0.2);   
  background-color: white;
  height: 70px;
}

.header_text{
  transition: 500ms;
  margin: 0px 30px;
  padding-top: 25px;
  padding-bottom: 25px;

  height: 5px;

  bottom: 0px;

  font-size:15px;
  color: var(--text-color);
  
  text-align: left;
  font-family: 'Futura';
  text-decoration-line: none;
  display: inline-block;
  position: relative;
  opacity: 0.75;
  border-bottom: 5px var(--header-one) transparent;
  
  
}

.header_text_active{
  transition: 500ms;
  border-bottom: 5px var(--header-one) solid;
  opacity: 1;
}

.blog_selected{
  transition: 500ms;
  margin: 0px 30px;
  padding-top: 25px;
  padding-bottom: 25px;

  height: 5px;

  bottom: 0px;

  font-size:15px;
  color: var(--text-color);
  
  text-align: left;
  font-family: 'Futura';
  text-decoration-line: none;
  display: inline-block;
  position: relative;
  opacity: 0.75;
  transition: 500ms;
  border-bottom: 5px var(--header-one) solid;
  opacity: 1;
}

.header_text:hover{
  transition: 500ms;
  border-bottom: 5px var(--header-one) solid;
  opacity: 1;
}

@media screen and (max-width: 800px) {
  #to_hide{display:none;}
  
}